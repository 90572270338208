<template>
  <el-row class="displays">
    <el-col :span="24">
      <div class="pages hidden-xs-only">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("registerTable").b }}
          </div>
        </div>
        <div class="tableBox">
          <!-- <div class="titleList" :class="lang ? '' : 'enStyle'">
            <div class="item c1">
              <div class="order"></div>
              {{ $t("topics_title_1") }}
            </div>
            <div class="item c2">
              <div class="order"></div>
              {{ $t("topics_title_2") }}
            </div>
            <div class="item c3">
              <div class="order"></div>
              {{ $t("topics_title_3") }}
            </div>
            <div class="item c4">
              <div class="order"></div>
              {{ $t("topics_title_4") }}
            </div>
          </div> -->
          <div class="table table2" :class="lang ? 'table1' : 'entable'">
            <div class="row1">
              <div class="t1">
                <div class="sanjiao">
                  <div class="date">{{ $t("parallelMeeting").t1 }}</div>
                  <div class="room">{{ $t("parallelMeeting").t2 }}</div>
                </div>
              </div>
              <!-- <div class="t2">
                <div class="top">{{ $t("parallelMeeting").d1 }}</div>
                <div class="bot">{{ $t("parallelMeeting").time1 }}</div>
              </div> -->
              <div class="t3">
                <div class="top">{{ $t("parallelMeeting").d2 }}</div>
                <div class="bot">
                  <div class="n">{{ $t("parallelMeeting").time2 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time3 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time4 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time5 }}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(1)">{{ $t("parallelMeeting").r1 }}</div>
              </div>
              <!-- <div class="t2 col1" @click="goDetails(0, 0)">
                {{ $t("parallelMeeting").list[0][0].sessionTitle }}
              </div> -->
              <div class="one">
                <div class="t3 col1" @click="goDetails(0, 1)">
                  {{ $t("parallelMeeting").list[0][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(0, 2)">
                  {{ $t("parallelMeeting").list[0][2].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(0, 3)">
                  {{ $t("parallelMeeting").list[0][3].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(0, 4)">
                  {{ $t("parallelMeeting").list[0][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(2)">{{ $t("parallelMeeting").r2 }}</div>
              </div>
              <!-- <div class="t2 col2" @click="goDetails(1, 0)">
                {{ $t("parallelMeeting").list[1][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col3" @click="goDetails(1, 1)">
                  {{ $t("parallelMeeting").list[1][1].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(1, 2)">
                  {{ $t("parallelMeeting").list[1][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(1, 3)">
                  {{ $t("parallelMeeting").list[1][3].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(1, 4)">
                  {{ $t("parallelMeeting").list[1][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(3)">{{ $t("parallelMeeting").r3 }}</div>
              </div>
              <!-- <div class="t2 col3" @click="goDetails(2, 0)">
                {{ $t("parallelMeeting").list[2][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col4" @click="goDetails(2, 1)">
                  {{ $t("parallelMeeting").list[2][1].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(2, 2)">
                  {{ $t("parallelMeeting").list[2][2].sessionTitle }}
                  <!-- <p @click="goDetails(2, 2, 2)">
                    {{ $t("parallelMeeting").list[2][2].type1[2].sessionTitle }}
                  </p> -->
                </div>
                <div class="t3 col2" @click="goDetails(2, 3)">
                  {{ $t("parallelMeeting").list[2][3].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(2, 4)">
                  {{ $t("parallelMeeting").list[2][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(4)">{{ $t("parallelMeeting").r4 }}</div>
              </div>
              <!-- <div class="t2 col1" @click="goDetails(3, 0)">
                {{ $t("parallelMeeting").list[3][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col1" @click="goDetails(3, 1)">
                  {{ $t("parallelMeeting").list[3][1].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(3, 2)">
                  {{ $t("parallelMeeting").list[3][2].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(3, 3)">
                  {{ $t("parallelMeeting").list[3][3].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(3, 4)">
                  {{ $t("parallelMeeting").list[3][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(5)">{{ $t("parallelMeeting").r5 }}</div>
              </div>
              <!-- <div class="t2 col3" @click="goDetails(4, 0)">
                {{ $t("parallelMeeting").list[4][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col4" v-if="lang" @click="goDetails(4, 1)">
                  {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                </div>
                <div class="t3 col4" v-else @click="goDetails(4, 1)">
                  {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                  <!-- <el-tooltip
                    class="item"
                    effect="dark"
                    :content="tops"
                    placement="top-start"
                  >
                    <span>
                      {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                    </span>
                  </el-tooltip> -->
                </div>
                <div class="t3 col2" @click="goDetails(4, 2)">
                  {{ $t("parallelMeeting").list[4][2].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(4, 3)">
                  {{ $t("parallelMeeting").list[4][3].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(4, 4)">
                  {{ $t("parallelMeeting").list[4][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(6)">{{ $t("parallelMeeting").r6 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(5, 0)">
                {{ $t("parallelMeeting").list[5][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(5, 1)">
                  {{ $t("parallelMeeting").list[5][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(5, 2)">
                  {{ $t("parallelMeeting").list[5][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(5, 3)">
                  {{ $t("parallelMeeting").list[5][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(5, 4)">
                  {{ $t("parallelMeeting").list[5][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(7)">{{ $t("parallelMeeting").r9 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(8, 1)">
                  {{ $t("parallelMeeting").list[8][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(8, 2)">
                  {{ $t("parallelMeeting").list[8][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(8, 3)">
                  {{ $t("parallelMeeting").list[8][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(8, 4)">
                  {{ $t("parallelMeeting").list[8][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(8)">{{ $t("parallelMeeting").r7 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(6, 1)">
                  {{ $t("parallelMeeting").list[6][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(6, 2)">
                  {{ $t("parallelMeeting").list[6][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(6, 3)">
                  {{ $t("parallelMeeting").list[6][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(6, 4)">
                  {{ $t("parallelMeeting").list[6][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(9)">{{ $t("parallelMeeting").r8 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(7, 1)">
                  {{ $t("parallelMeeting").list[7][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(7, 2)">
                  {{ $t("parallelMeeting").list[7][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(7, 3)">
                  {{ $t("parallelMeeting").list[7][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(7, 4)">
                  {{ $t("parallelMeeting").list[7][4].sessionTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="pages1 hidden-sm-and-up">
        <div class="banner">
          <img src="@/assets/images/parallelMeetings/banner.png" alt="" />
          <div class="title" :class="{ weibei: lang }">
            {{ $t("registerTable").b }}
          </div>
        </div>
        <div class="tableBox">
          <!-- <div class="titleList">
            <div class="item c1">
              <div class="order"></div>
              <span style="flex: 1">{{ $t("topics_title_1") }}</span>
            </div>
            <div class="item c2">
              <div class="order"></div>
              <span style="flex: 1">{{ $t("topics_title_2") }}</span>
            </div>
            <div class="item c3">
              <div class="order"></div>
              <span style="flex: 1">{{ $t("topics_title_3") }}</span>
            </div>
            <div class="item c4">
              <div class="order"></div>
              <span style="flex: 1">{{ $t("topics_title_4") }}</span>
            </div>
          </div> -->
          <div class="table">
            <div class="row1">
              <div class="t1">
                <div class="sanjiao">
                  <div class="date">{{ $t("parallelMeeting").t1 }}</div>
                  <div class="room">{{ $t("parallelMeeting").t2 }}</div>
                </div>
              </div>
              <!-- <div class="t2">
                <div class="top">{{ $t("parallelMeeting").d1 }}</div>
                <div class="bot">{{ $t("parallelMeeting").time1 }}</div>
              </div> -->
              <div class="t3">
                <div class="top">{{ $t("parallelMeeting").d2 }}</div>
                <div class="bot">
                  <div class="n">{{ $t("parallelMeeting").time2 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time3 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time4 }}</div>
                  <div class="n">{{ $t("parallelMeeting").time5 }}</div>
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(1)">{{ $t("parallelMeeting").r1 }}</div>
              </div>
              <!-- <div class="t2 col1" @click="goDetails(0, 0)">
                {{ $t("parallelMeeting").list[0][0].sessionTitle }}
              </div> -->
              <div class="one">
                <div class="t3 col1" @click="goDetails(0, 1)">
                  {{ $t("parallelMeeting").list[0][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(0, 2)">
                  {{ $t("parallelMeeting").list[0][2].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(0, 3)">
                  {{ $t("parallelMeeting").list[0][3].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(0, 4)">
                  {{ $t("parallelMeeting").list[0][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(2)">{{ $t("parallelMeeting").r2 }}</div>
              </div>
              <!-- <div class="t2 col2" @click="goDetails(1, 0)">
                {{ $t("parallelMeeting").list[1][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col3" @click="goDetails(1, 1)">
                  {{ $t("parallelMeeting").list[1][1].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(1, 2)">
                  {{ $t("parallelMeeting").list[1][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(1, 3)">
                  {{ $t("parallelMeeting").list[1][3].sessionTitle }}
                </div>
                <div class="t3 col1">
                  {{ $t("parallelMeeting").list[1][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(3)">{{ $t("parallelMeeting").r3 }}</div>
              </div>
              <!-- <div class="t2 col3" @click="goDetails(2, 0)">
                {{ $t("parallelMeeting").list[2][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col4" @click="goDetails(2, 1)">
                  {{ $t("parallelMeeting").list[2][1].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(2, 2)">
                  {{ $t("parallelMeeting").list[2][2].sessionTitle }}
                  <!-- <p @click="goDetails(2,2,2)">{{$t('parallelMeeting').list[2][2].type1[2].sessionTitle}}</p> -->
                </div>
                <div class="t3 col2" @click="goDetails(2, 3)">
                  {{ $t("parallelMeeting").list[2][3].sessionTitle }}
                </div>
                <div class="t3 col4">
                  {{ $t("parallelMeeting").list[2][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <div>{{ $t("parallelMeeting").room }}</div>
                <div @click="goRoom(4)">{{ $t("parallelMeeting").r4 }}</div>
              </div>
              <!-- <div class="t2 col1" @click="goDetails(3, 0)">
                {{ $t("parallelMeeting").list[3][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col1" @click="goDetails(3, 1)">
                  {{ $t("parallelMeeting").list[3][1].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(3, 2)">
                  {{ $t("parallelMeeting").list[3][2].sessionTitle }}
                </div>
                <div class="t3 col4" @click="goDetails(3, 3)">
                  {{ $t("parallelMeeting").list[3][3].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(3, 4)">
                  {{ $t("parallelMeeting").list[3][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(5)">{{ $t("parallelMeeting").r5 }}</div>
              </div>
              <!-- <div class="t2 col3" @click="goDetails(4, 0)">
                {{ $t("parallelMeeting").list[4][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col4" v-if="lang" @click="goDetails(4, 1)">
                  {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                </div>
                <div class="t3 col4" v-else @click="goDetails(4, 1)">
                  {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                  <!-- <el-tooltip
                    class="item"
                    effect="dark"
                    :content="tops"
                    placement="top-start"
                  >
                    <span>
                      {{ $t("parallelMeeting").list[4][1].sessionTitle }}
                    </span>
                  </el-tooltip> -->
                </div>
                <div class="t3 col2" @click="goDetails(4, 2)">
                  {{ $t("parallelMeeting").list[4][2].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(4, 3)">
                  {{ $t("parallelMeeting").list[4][3].sessionTitle }}
                </div>
                <div class="t3 col3" @click="goDetails(4, 4)">
                  {{ $t("parallelMeeting").list[4][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(6)">{{ $t("parallelMeeting").r6 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(5, 0)">
                {{ $t("parallelMeeting").list[5][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(5, 1)">
                  {{ $t("parallelMeeting").list[5][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(5, 2)">
                  {{ $t("parallelMeeting").list[5][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(5, 3)">
                  {{ $t("parallelMeeting").list[5][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(5, 4)">
                  {{ $t("parallelMeeting").list[5][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(7)">{{ $t("parallelMeeting").r9 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(8, 1)">
                  {{ $t("parallelMeeting").list[8][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(8, 2)">
                  {{ $t("parallelMeeting").list[8][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(8, 3)">
                  {{ $t("parallelMeeting").list[8][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(8, 4)">
                  {{ $t("parallelMeeting").list[8][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(8)">{{ $t("parallelMeeting").r7 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(6, 1)">
                  {{ $t("parallelMeeting").list[6][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(6, 2)">
                  {{ $t("parallelMeeting").list[6][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(6, 3)">
                  {{ $t("parallelMeeting").list[6][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(6, 4)">
                  {{ $t("parallelMeeting").list[6][4].sessionTitle }}
                </div>
              </div>
            </div>
            <div class="row2">
              <div class="t1">
                <!-- <div>{{ $t("parallelMeeting").room1 }}</div> -->
                <div @click="goRoom(9)">{{ $t("parallelMeeting").r8 }}</div>
              </div>
              <!-- <div class="t2 col4" @click="goDetails(6, 0)">
                {{ $t("parallelMeeting").list[6][0].sessionTitle }}
              </div> -->

              <div class="one">
                <div class="t3 col2" @click="goDetails(7, 1)">
                  {{ $t("parallelMeeting").list[7][1].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(7, 2)">
                  {{ $t("parallelMeeting").list[7][2].sessionTitle }}
                </div>
                <div class="t3 col1" @click="goDetails(7, 3)">
                  {{ $t("parallelMeeting").list[7][3].sessionTitle }}
                </div>
                <div class="t3 col2" @click="goDetails(7, 4)">
                  {{ $t("parallelMeeting").list[7][4].sessionTitle }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      tops: this.$t("parallelMeeting").list[4][1].sessionTitle,
    };
  },
  watch: {
    $route(to, from) {
      if (to.path == "/account") {
        if (this._isMobile()) {
          console.log(123);
          if (this.$route.params.type == 0) {
            let section = document.getElementById("zhuban1");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
          if (this.$route.params.type == 1) {
            let section = document.getElementById("cenban1");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
        } else {
          if (this.$route.params.type == 0) {
            let section = document.getElementById("zhuban");
            if (section) {
              console.log(section);
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
          if (this.$route.params.type == 1) {
            console.log(45123);
            let section = document.getElementById("cenban");
            if (section) {
              this.$nextTick(() => {
                section.scrollIntoView();
              });
            }
          }
        }
      }
    },
  },
  mounted() {},
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goRoom(i) {
      switch (i) {
        case 1:
          window.open(
            "https://zoom.us/j/93366654754?pwd=WmFuYUlFeHZJaU91TUorUW0zS1FVZz09"
          );
          break;
        case 2:
          window.open(
            "https://zoom.us/j/98920865684?pwd=aUFrWWdxVmZMY1FYOXdRR1VPT0RVdz09"
          );
          break;
        case 3:
          window.open(
            "https://zoom.us/j/92815064455?pwd=ejh1Z1ZQbkNrNEtad3dvTEhkdXBvZz09"
          );
          break;
        case 4:
          window.open(
            "https://zoom.us/j/94044414598?pwd=VlR2N0tuRm1GY2RvN1ZpTDNkTkkxUT09"
          );
          break;
        case 5:
          window.open(
            "https://zoom.us/j/95002423197?pwd=RGtjb1J6N0I3eFZTVk9TWW5DbHdkZz09"
          );
          break;
        case 6:
          window.open(
            "https://zoom.us/j/98647814002?pwd=aHIwa3YwUXZDS3hNQmRicXgyQVFsQT09"
          );
          break;
        case 7:
          window.open(
            "https://zoom.us/j/94596855046?pwd=eERNQjR1WTRsR1U5RmVCYXFPTXhKQT09"
          );
          break;
        case 8:
          window.open(
            "https://zoom.us/j/93902101729?pwd=aVpBaUF0azM0cUpNcm1BL3VYQ0tEdz09"
          );
          break;
        case 9:
          window.open(
            "https://zoom.us/j/96176310060?pwd=NW9jci9iOGMxOXhvTUo3VFkyckFzdz09"
          );
          break;

        default:
          break;
      }
    },
    goDetails(i, t, y) {
      this.$router.push({
        path: "/parallelMeetingDetails",
        query: { i: i, t: t, y: y },
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
.displays {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Pageconter {
  margin: auto;
}

.pages {
  width: 100%;

  .banner {
    width: 100%;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -170px;
      margin-top: -40px;
      width: 340px;
      height: 80px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      line-height: 80px;
      font-weight: 700;
      font-size: 42px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  .tableBox {
    max-width: 1500px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: auto;
    margin-top: 22px;
    margin-bottom: 29px;
    padding: 20px 20px 58px;
    box-sizing: border-box;

    .titleList {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 46px 37px;
      box-sizing: border-box;
      flex-wrap: wrap;

      .item {
        font-size: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .order {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #B34137;
          margin-right: 10px;
        }
      }

      .c1 {
        color: #1D94BF;

        .order {
          background: #1D94BF;
        }
      }

      .c2 {
        color: #B34137;

        .order {
          background: #B34137;
        }
      }

      .c3 {
        color: #AB7B2A;

        .order {
          background: #AB7B2A;
        }
      }

      .c4 {
        color: #8DC63F;

        .order {
          background: #8DC63F;
        }
      }
    }

    .enStyle {
      .item {
        width: 570px;
        justify-content: flex-start;
      }
    }

    .table {
      width: 100%;

      .row1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .t1 {
          width: 14%;
          height: 111px;
          background: #405E3C;
          border-right: 1px solid #CFDECC;
          border-bottom: 1px solid #CFDECC;
          box-sizing: border-box;
          color: #ffffff;
          overflow: hidden;
          position: relative;

          .sanjiao {
            width: 0;
            height: 0;
            border-top: 111px solid #405e3c;
            border-bottom: 0px solid yellow; /* 1.底边去除就变成直角三角形 */
            border-left: 12.9rem solid #8dc63f;

            .date {
              position: absolute;
              top: 24px;
              right: 18%;
              font-weight: bold;
            }

            .room {
              position: absolute;
              bottom: 8px;
              left: 18%;
              width: 90px;
              height: 30px;
              font-weight: bold;
            }
          }
        }

        .t2 {
          width: 17%;
          height: 110px;
          background: #405E3C;
          box-sizing: border-box;
          border-right: 1px solid #CFDECC;
          color: #FFFFFF;

          .top {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
          }

          .bot {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
          }
        }

        .t3 {
          flex: 1;
          background: #405E3C;
          height: 110px;
          box-sizing: border-box;
          color: #FFFFFF;

          .top {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            font-weight: 700;
          }

          .bot {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;

            .n {
              width: 25%;
              height: 100%;
              border-right: 1px solid #CFDECC;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .row2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .t1 {
          width: 14%;
          height: 120px;
          background: #8DC63F;
          border-right: 1px solid #CFDECC;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          align-content: center;
          border-bottom: 1px solid #CFDECC;

          div {
            width: 100%;
            color: #ffffff;
            font-weight: 700;
            font-size: 16px;
            margin-bottom: 10px;
          }
        }

        .t2 {
          width: 17%;
          height: 120px;
          font-size: 16px;
          box-sizing: border-box;
          border-right: 1px solid #CFDECC;
          border-bottom: 1px solid #CFDECC;
          padding: 15px 5px;
          cursor: pointer;
          text-align: left;
          overflow: hidden;
        }

        .one {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .t3 {
            width: 25%;
            height: 120px;
            padding: 15px 5px;
            font-size: 16px;
            box-sizing: border-box;
            border-right: 1px solid #CFDECC;
            border-bottom: 1px solid #CFDECC;
            cursor: pointer;
            text-align: left;
            overflow: hidden;
          }
        }
      }
    }

    .table1 {
      .row2 {
        .t2 {
          padding: 15px !important;
        }

        .t3 {
          padding: 15px !important;
        }
      }
    }

    .entable {
      .row2 {
        .t3 {
          height: 130px !important;
          display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
          -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-line-clamp: 9; /* 2行，只有 webkit内核支持 */
          overflow: hidden;
        }

        .t2 {
          height: 130px !important;
          display: -webkit-box; /* 将对象作为弹性伸缩盒子模型显示 */
          -webkit-box-orient: vertical; /* 设置或检索伸缩盒对象的子元素的排列方式 */
          -webkit-line-clamp: 9; /* 2行，只有 webkit内核支持 */
          overflow: hidden;
        }

        .t1 {
          height: 130px !important;
        }
      }
    }
  }
}

.pages1 {
  width: 100%;

  .banner {
    width: 100%;
    height: 190px;
    background: #405E3C;
    position: relative;

    .title {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-left: -60px;
      margin-top: -25px;
      width: 120px;
      height: 50px;
      background: rgba(141, 198, 63, 0.9);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: 16px;
      color: #FFFFFF;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .tableBox {
    max-width: 1400px;
    background: #FFFFFF;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    margin: auto;
    margin-top: 22px;
    margin-bottom: 29px;
    padding: 20px 10px 20px;
    box-sizing: border-box;

    .titleList {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 15px 30px;
      box-sizing: border-box;
      flex-wrap: wrap;

      .item {
        font-size: 15px;
        position: relative;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        .order {
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #B34137;
          margin-right: 10px;
          margin-top: 8px;
        }
      }

      .c1 {
        color: #1D94BF;

        .order {
          background: #1D94BF;
        }
      }

      .c2 {
        color: #B34137;

        .order {
          background: #B34137;
        }
      }

      .c3 {
        color: #AB7B2A;

        .order {
          background: #AB7B2A;
        }
      }

      .c4 {
        color: #8DC63F;

        .order {
          background: #8DC63F;
        }
      }
    }

    .table {
      width: 100%;

      .row1 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .t1 {
          width: 12%;
          height: 88px;
          background: #405E3C;
          border-right: 1px solid #CFDECC;
          border-bottom: 1px solid #CFDECC;
          box-sizing: border-box;
          color: #ffffff;
          overflow: hidden;
          position: relative;
          font-size: 12px;

          .sanjiao {
            width: 0;
            height: 0;
            border-top: 111px solid #405e3c;
            border-bottom: 0px solid yellow; /* 1.底边去除就变成直角三角形 */
            border-left: 2.5rem solid #8dc63f;

            .date {
              position: absolute;
              top: 24px;
              right: 9%;
            }

            .room {
              position: absolute;
              bottom: 20px;
              left: 9%;
            }
          }
        }

        .t2 {
          width: 16%;
          height: 110px;
          background: #405E3C;
          box-sizing: border-box;
          border-right: 1px solid #CFDECC;
          color: #FFFFFF;

          .top {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
          }

          .bot {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
        }

        .t3 {
          flex: 1;
          background: #405E3C;
          height: 88px;
          box-sizing: border-box;
          color: #FFFFFF;

          .top {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
            font-weight: 700;
          }

          .bot {
            box-sizing: border-box;
            width: 100%;
            height: 50%;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;

            .n {
              width: 25%;
              height: 100%;
              border-right: 1px solid #CFDECC;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }
      }

      .row2 {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .t1 {
          width: 12%;
          height: 120px;
          background: #8DC63F;
          border-right: 1px solid #CFDECC;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          align-content: center;
          border-bottom: 1px solid #CFDECC;

          div {
            width: 100%;
            color: #ffffff;
            font-weight: 700;
            font-size: 12px;
          }
        }

        .t2 {
          width: 16%;
          height: 120px;
          box-sizing: border-box;
          border-right: 1px solid #CFDECC;
          border-bottom: 1px solid #CFDECC;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          font-size: 12px;
          cursor: pointer;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 8;
          word-break: break-word;
        }

        .one {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;

          .t3 {
            width: 25%;
            height: 120px;
            padding: 5px;
            box-sizing: border-box;
            border-right: 1px solid #CFDECC;
            border-bottom: 1px solid #CFDECC;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            font-size: 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            word-break: break-word;
          }
        }
      }
    }
  }
}

.col1 {
  &:hover {
    color: #ab7b2a;
  }
}

.col2 {
  &:hover {
    color: #B34137;
  }
}

.col3 {
  &:hover {
    color: #1d94bf;
  }
}

.col4 {
  &:hover {
    color: #8DC63F;
  }
}
</style>